import { LibraryData, ProductState, PricingData, CountryData, ConfigState, ApplicationState, LogoType, StraussLogoType } from "../store/interfaces";
import { addPrice, applyVat, getFinalPricingNoVat, getShippingPrice, getVat } from "./pricing";

declare global {
    interface Window {
        _paq: any;
    }
}

function leftFill(num:string|undefined, targetLength:number) {
    if (num === undefined) {
        return '--';
    }
    return num.padStart(targetLength, '-');
}

export function logAddToCart(state: ApplicationState, name: string, size: string) {
    if(window._paq === undefined) {
        console.warn("Piwik integration not found.");
        return
    }
    const productData = state.library.products.find((product) => {return product.article === name});
    let logoNumber = '1';
    if (state.config.straussLogo === StraussLogoType.StitchedBlack) {
        logoNumber = '2';
    } else if (state.config.straussLogo === StraussLogoType.StitchedWhite) {
        logoNumber = '3';
    }
    const articleNumber = `${leftFill(productData?.article, 2)}${state.config.baseColorId}${state.config.contrastColorId}${logoNumber}${leftFill(size, 3)}`;
    window._paq.push([
        "ecommerceAddToCart",
        [{
            sku: productData?.article,
            name: productData?.name.en,
            //category: kann mitgegeben werden? __entfällt?__,
            price: productData?.pricing[0].pricing.eur,
            quantity: 1,
            brand: "Strauss",
            variant: articleNumber, //"SalesArticleVariantKey (z.B. Shorte.s.motion 2020 à “65513-722-223”) (SalesArticleNo – ColourCode – SizeCode)", //Todo(daniel)
            customDimensions:{
                '1': productData?.article,
                '2': state.config.baseColorId,
                '3': size,
                '4': productData?.hasVentilationZipper ? "mit Belüftung" : "ohne Belüftung",
                '5': "myMotion",
                '6': "Keine", 
                '7': state.config.straussLogo,
                '8': state.config.contrastColorId,
                '9': state.ui.country.currency,
                '10': state.ui.country.id
            }
        }]
    ]);
}

export function logRemoveFromCart(state: ApplicationState, index: number) {
    if(window._paq === undefined) {
        console.warn("Piwik integration not found.");
        return
    }
    const productState = state.config.products[index];
    const productData = state.library.products.find((product) => {return product.article === productState.article});
    let logoNumber = '1';
    if (state.config.straussLogo === StraussLogoType.StitchedBlack) {
        logoNumber = '2';
    } else if (state.config.straussLogo === StraussLogoType.StitchedWhite) {
        logoNumber = '3';
    }
    let logProducts: any[] = [];

    productState.order.forEach((orderEntry) => {
        let logoType : LogoType|null = null;
        if (productState.logos.length > 0 ) {
            logoType = productState.logos[0].logoType;
        }
        const articleNumber = `${leftFill(productData?.article, 2)}${state.config.baseColorId}${state.config.contrastColorId}${logoNumber}${leftFill(orderEntry.size, 3)}`;
        logProducts.push(
            {
                sku: productData?.article,
                name: productData?.name.en,
                //category: kann mitgegeben werden? __entfällt?__,
                price: productData?.pricing[0].pricing.eur,
                quantity: orderEntry.amount,
                brand: "Strauss",
                variant: articleNumber,
                customDimensions:{
                    '1': productData?.article,
                    '2': state.config.baseColorId,
                    '3': orderEntry.size,
                    '4': productData?.hasVentilationZipper ? "mit Belüftung" : "ohne Belüftung",
                    '5': "myMotion",
                    '6': logoType === null ? "Keine" : (logoType === LogoType.Stitched ? "Direkteinstickung" : "Transferdruck"), 
                    '7': state.config.straussLogo,
                    '8': state.config.contrastColorId,
                    '9': state.ui.country.currency,
                    '10': state.ui.country.id
                }
            }
        );
    });
    window._paq.push([
        "ecommerceRemoveFromCart",
        logProducts
    ]);
}

export function logOrder(state: ApplicationState) {
    if(window._paq === undefined) {
        console.warn("Piwik integration not found.");
        return
    }
    let logProducts: any[] = [];
    let logoNumber = '1';
    if (state.config.straussLogo === StraussLogoType.StitchedBlack) {
        logoNumber = '2';
    } else if (state.config.straussLogo === StraussLogoType.StitchedWhite) {
        logoNumber = '3';
    }

    console.log("order logging", state.config);

    state.config.products.forEach(productState => {
        const productData = state.library.products.find((product) => {return product.article === productState.article});
        
        productState.order.forEach((orderEntry) => {
            let logoType : LogoType|null = null;
            if (productState.logos.length > 0 ) {
                logoType = productState.logos[0].logoType;
            }
            const articleNumber = `${leftFill(productData?.article, 2)}${state.config.baseColorId}${state.config.contrastColorId}${logoNumber}${leftFill(orderEntry.size, 3)}`;
            logProducts.push({
                sku: productData?.article,
                name: productData?.name.en,
                //category: kann mitgegeben werden? __entfällt?__,
                price: productData?.pricing[0].pricing.eur,
                quantity: orderEntry.amount,
                brand: "Strauss",
                variant: articleNumber,
                customDimensions:{
                    '1': productData?.article,
                    '2': state.config.baseColorId,
                    '3': orderEntry.size,
                    '4': productData?.hasVentilationZipper ? "mit Belüftung" : "ohne Belüftung",
                    '5': "myMotion",
                    '6': logoType === null ? "Keine" : (logoType === LogoType.Stitched ? "Direkteinstickung" : "Transferdruck"), 
                    '7': state.config.straussLogo,
                    '8': state.config.contrastColorId,
                    '9': state.ui.country.currency,
                    '10': state.ui.country.id
                }
            });
        });
    });

    const totalAmountNoVat = getFinalPricingNoVat(state.library, state.config.products);
    const shippingPrice = getShippingPrice(state.library, totalAmountNoVat);
    const totalPlusShipping = addPrice(totalAmountNoVat, shippingPrice);    
    const totalVat = getVat(totalPlusShipping, state.ui.country.vat);
    const totalAmountVat = applyVat(totalPlusShipping, state.ui.country.vat);    

    window._paq.push([
        "ecommerceOrder",
        logProducts,
        {
            orderId: state.config.code,
            grandTotal: totalPlusShipping.eur,
            subTotal: totalAmountNoVat.eur,
            tax: totalVat.eur,
            shipping: shippingPrice.eur,
            discount: 0
        }
    ]);
    console.log("order logged!");
}

