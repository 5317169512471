let translation = 
{
    "translation": {
        "key": "fr",
        "mobile": {
            "landscape": "Veuillez mettre votre appareil en mode portrait.",
            "info": {
                "header": "Besoin d'aide pour votre configuration ?",
                "text": "Si vous avez des questions sur le configurateur mymotion, n'hésitez pas à nous contacter.<br>Contactez-nous : +49 60 50 / 97 10 12"
            }
        },
        "cookie": {
            "ok": "OK",
            "message": "STRAUSS utilise des cookies sur cette page. Vous pouvez trouver plus d'informations sur notre <a target=\"_blank\" href=\"https://www.engelbert-strauss.de/Rechtliches/Mymotionkonfigurator_Datenschutz\">page de protection</a> des données."
        },
        "splashscreen": {
            "link": "CRÉEZ VOTRE E.S. MYMOTION",
            "code": {
                "placeholder": "CI-MY12345",
                "continue": "Continuer",
                "info": "Vous avez déjà enregistré un design ?<br>Entrez votre code ici."
            }
        },
        "logos": {
            "standard": "Défaut",
            "stitchedBlack": "Brodé en noir",
            "stitchedWhite": "Brodé en blanc"
        },
        "gender": {
            "female": "Femmes",
            "male": "Hommes"
        },
        "tags": {
            "motion2020": "Motion 2020",
            "motion": "Motion",
            "pants": "Panatalons",
            "jackets": "Vestes"
        },
        "notfound": {
            "title": "PAGE NON TROUVÉE",
            "subtitle": "La page à laquelle vous tentez d'accéder n'est pas disponible. Vous avez peut-être mal tapé l'adresse ou la page a été déplacée."
        },
        "editscreen": {
            "inspire": "Laissez-vous inspirer",
            "orderlist": "Liste de commandes",
            "orderlistView": "Voir la liste de commandes",
            "products": "Produits",
            "colors": "Vos couleurs",
            "logos": "Votre logo",
            "baseColor": "Couleur de base",
            "contrastColor": "Couleur de contraste",
            "straussLogo": "Logo Strauss",
            "save": "Enregistrer mymotion",
            "chooseproduct": "Sélectionner des produits",
            "selection": "Sélection",
            "incVat": "avec TVA",
            "pricingFrom": "De {{amount}} pièces: {{price}} avec TVA",
            "pricingFromNoVat": "De {{amount}} pièces: {{price}} sans TVA",
            "pricingInfo": "Achetez plus, économisez plus - avec de plus grandes quantités, vous bénéficiez d'un prix unitaire inférieur.",
            "minAmount": "La quantité minimale de commande pour un produit est de {{amount}} pièces et peut être obtenue en combinant différentes tailles et les versions respectives femme et homme.",
            "incVentilation": "Ventilation zippée à l'arrière des cuisses",
            "excVentilation": "Sans ventilation zippée à l'arrière des cuisses",
            "invVentilationShort": "Avec fermeture éclair",
            "excVentilationShort": "Sans fermeture éclair",
            "ventilationInfo": "<b>Ventilation zippée à l'arrière des cuisses</b><br>Vos produits mymotion 2020 CI personallisés peuvent être fabriqués avec ou sans ventilation zippée à l'arrière des cuisses.",
            "addToOrderList": "ajouter à la liste",
            "addToOrderListAlreadyOn": "déjà ajouté",
            "configProduct": "Configurer",
            "basics": "DÉCOUVRIR DES BASIQUES ASSORTIS",
            "personalize": "Personnaliser",
            "changeProduct": "Changer de produit",
            "productDetails": "Détails du produit",
            "showAdditionalInfo": "Voir la description du produit",
            "closeAdditionalInfo": "Fermer la description du produit",
            "logo": {
                "header": "VOTRE LOGO",
                "intro": "Votre logo comme élément distinctif. Décidez d'un type de personnalisation et téléchargez jusqu'à deux logos. Sélectionnez ensuite la zone de placement et la taille du logo souhaitées. Si vous ne disposez pas encore d'un fichier de logo approprié, sélectionnez l'option appropriée. Notre équipe d'experts vous aidera personnellement après le processus de commande. Si vous ne souhaitez pas de personnalisation de logo sur les produits, ajoutez-les simplement à votre liste de commande sans logos.",
                "info": "Notification",
                "infoText": "Les zones numérotées sur le produit indiquent les options de placement du logo. Vous recevrez un aperçu de ce à quoi ressemblera votre logo sur le produit de notre équipe d'experts sous forme de PDF lors de la consultation après votre demande de commande.",
                "upload": {
                    "header": "Télécharger le logo",
                    "info": "Téléchargez jusqu'à 2 logos différents pour personnaliser vos vêtements.",
                    "drag": "Faites glisser votre logo ici pour le télécharger.",
                    "or": "OU",
                    "browse": "Parcourir les fichiers",
                    "fileformats": "Formats de fichiers pris en charge : <b>.EPS</b>, <b>.AI</b> <b>.PDF</b>, <b>.CDR</b> ou <b>.JPG</ b> - Taille de fichier maximale : <b>19 Mo</b>",
                    "rights": "En téléchargeant un logo, vous confirmez que vous ne pas violer le nom, la marque et les droits d'auteur ainsi que d'autres droits de tiers et de lois. Toutes les informations légales peuvent être trouvées <a target=\"_blank\" href=\"https://www.engelbert-strauss.de/Rechtliches/Mymotionkonfigurator_Datenschutz\">ici.</a>"
                },
                "type": {
                    "print": "Impression par transfert",
                    "stitch": "Broderie directe"
                },
                "transferInfo": "La solution de finition polyvalente :<br>une impression sur film résistante pour presque tous les textiles jusqu'à 900 cm² - couleurs spéciales et dégradés possibles.<br><br>Plus la quantité de commande est élevée, plus le prix de votre personnalisation est bas.<br> (Les prix dépendent de la taille du logo en cm²)",
                "directInfo": "Particulièrement élégant et résistante : Votre finition est brodée directement - nombreux types de polices, motifs et coloris possibles.<br>(Les prix dépendent de la taille du logo en cm²)",
                "add": "Placer le premier logo",
                "addAdditional": "Placer le deuxième logo",
                "existing": "Logos déjà placés",
                "placement": "Placement",
                "placementEmpty": "Choisir l'emplacement",
                "placementHelp": "Cliquez sur un visage à gauche ou sélectionnez un visage ci-dessous pour ajouter un logo.",
                "noLogo": "Continuer sans logo",
                "sizeEmpty": "Sélectionnez la taille",
                "place": "Placer le logo",
                "placeFirst": "Placer le logo et ajouter à la liste de commandes",
                "area": "Placement",
                "front": "à l'avant",
                "back": "à l'arrière",
                "side": "sur le côté",
                "uploadFirst": "Télécharger le logo",
                "uploadSecond": "Télécharger le deuxième logo"
            },
            "additionalProductInfos": {
                "knee": "<b>Norme supplémentaire grâce à l'utilisation de genouillères</b><br>Travailler à genoux - cela signifie une énorme pression sur vos genoux ! Il existe un risque de maladies chroniques et de blessures causées par des objets au sol. Protégez vos genoux de ces dangers.<br><br><b>Voici comment est fabriquée la protection des genoux:</b><br>Ce pantalon répond aux exigences de la « protection des genoux pour le travail à genoux » de type 2, niveau de performance 1 selon DIN EN 14404:2004+A1:2010 en combinaison avec la protection certifiée e.s. genouillères.",
                "zipper": "<b>Ventilation zippée à l'arrière des cuisses</b><br>Votre e.s. individuel Les produits mymotion 2020 CI peuvent être fabriqués avec ou sans ventilation zippée à l'arrière des cuisses.",
                "jacketzipper": ""
            }
        },
        "save": {
            "header": "ENREGISTREZ VOTRE MYMOTION",
            "intro": "Vous pouvez enregistrer votre configuration pour continuer plus tard ou pour la partager. Pour cela, copiez simplement le code ci-dessous.",
            "choices": "VOTRE SÉLECTION PRÉCÉDENTE",
            "code": "VOTRE CODE INDIVIDUEL",
            "copyCode": "Copier le code",
            "info": "Nous sauvegardons votre configuration pour les 60 prochains jours. Vous pouvez y accéder à tout moment pendant cette période.",
            "copied": "Le code a été copié dans le presse-papier!"
        },
        "inspirationscreen": {
            "header": "INSPIREZ-VOUS DE NOS CRÉATEURS DE VÊTEMENTS DE TRAVAIL",
            "use": "PRENEZ CETTE COMBINAISON"
        },
        "orderscreen": {
            "backToOverview": "Retour à l'aperçu",
            "header": "Bon choix! Ce sont vos articles",
            "subHeader": "Veuillez sélectionner le nombre et la taille de chaque article et compléter la liste de commande.",
            "headerEmpty": "Dommage...",
            "subHeaderEmpty": "Votre liste de commandes est encore vide.",
            "priceWithoutVat": "{{price}} sans TVA",
            "priceWithVat": "{{price}} avec TVA",
            "minimumOrder": "La quantité minimale de commande pour un produit est de {{pieces}} pièces et peut être obtenue en combinant différentes tailles et les versions respectives femme et homme.",
            "amount": "Nombre",
            "size": "Taille",
            "addSize": "ajouter une taille",
            "removeSize": "supprimer la taille",
            "nextStep": "L'étape suivante",
            "sum": {
                "totalNoVat": "Total",
                "shipping": "Frais d'envoi",
                "free": "gratuit",
                "additionalVat": " + TVA",
                "totalWithVat": "coût total des articles",
                "withVat": "avec TVA",
                "additionalCosts": "plus frais de personnalisation"
            },
            "personalized": {
                "consultingHeader": "Conseils sur les<br>produits mymotion personnalisés",
                "callHeader": "Nous vous appelons",
                "callText": "Nous vous contacterons dans les plus brefs délais concernant le type, le placement et le tarif de votre personnalisation."
            },
            "basics": {
                "header": "Vous souhaitez des bases adaptées à votre configuration ?",
                "text": "Avec votre demande, nos conseillers à la clientèle recevront une note indiquant que vous souhaitez ajouter des produits de base adaptés à votre commande. Lors d'un entretien personnel, des éléments adaptés à votre configuration personnelle mymotion vous seront proposés."
            },
            "inspectColors": {
                "header": "Souhaitez-vous vérifier votre sélection de couleurs à l'avance ?",
                "text": "Nous offrons la livraison gratuite d'échantillons de tissu avec toutes les couleurs MyMotion. De cette façon, vous pouvez comparer les différents dégradés de couleurs avec votre sélection actuelle."
            },
            "inspectSize": {
                "header": "Souhaitez-vous vérifier votre sélection de taille à l'avance ?",
                "text": "Vous ne savez pas de quelle taille votre équipe a besoin ? Nous nous ferons un plaisir de vous envoyer gratuitement des échantillons de différentes tailles. Ceux-ci ne sont pas encore livrés avec votre configuration individuelle."
            },
            "getInspired": "Laissez-vous inspirer",
            "addProducts": "Sélectionner des produits"
        },
        "progress": {
            "orderlist": "Liste de commandes",
            "customerdata": "Données client",
            "overview": "Aperçu de la commande"
        },
        "current": {
            "header": "Votre configuration actuelle",
            "uploadedLogo": "Logo téléchargé",
            "save": "Enregistrez et partagez votre configuration"
        },
        "additionalProducts": {
            "header": "Ajoutez plus de produits",
            "addNow": "Ajouter maintenant",
            "price": "<b>à partir de {{price}}</b> (sans TVA)",
            "fromAmount": "de {{pieces}} St."
        },
        "customerscreen": {
            "contact": {
                "orderFor": "Comander pour:",
                "customerNumber": "Numéro de client (facultatif)",
                "firstName": "Prénom",
                "lastName": "Nom de famille",
                "companyName": "Nom de l'entreprise",
                "companyContact": "Personne de contact",
                "country": "Pays",
                "zipCode": "Code postal",
                "city": "Ville/lieu",
                "address": "Adresse",
                "title": "Salutation",
                "occupation": "Profession",
                "sector": "Le secteur",
                "email": "Adresse e-mail",
                "phone": "Numéro de téléphone",
                "comment": "Commenter (facultatif)",
                "titleFemale": "Mme",
                "titleMale": "Monsieur"
            },
            "orderFor": {
                "company": "Entreprise",
                "practice": "Cabinet",
                "private": "Privé"
            },
            "backToOrderscreen": "Retour à la liste des commandes"
        },
        "finalscreen": {
            "backToCustomerscreen": "Retour aux données clients",
            "header": "Dernière vérification - tout va bien ?",
            "subHeader": "Veuillez vérifier toutes les données avant de soumettre votre demande.",
            "furtherActions": {
                "header": "COMMENT CONTINUER",
                "step1Header": "Finalisation avec notre service client",
                "step1Text": "Notre service client vous contactera dans les plus brefs délais afin de mettre en œuvre vos souhaits de la meilleure façon possible.",
                "step2Header": "Début de la fabrication",
                "step2Text": "Votre commande sera transmise à notre production et mise en service. Vos produits mymotion personnels sont en cours de production.",
                "step3Header": "Expédition de vos produits mymotion",
                "step3Text": "Votre commande est maintenant en route vers vous."
            },
            "policyAccept": "Je suis d'accord",
            "eula": "J'ai lu les <a target=\"_blank\" href=\"{{link}}\">CGV</a> du \"CONFIGURATEUR CI MYMOTION\" et je les accepte.",
            "send": "Envoyer",
            "error": "Une erreur s'est produite lors de la soumission de votre commande. Veuillez réessayer et contactez-nous au +49 60 50 / 97 10 12 si cette erreur persiste."
        },
        "postscreen": {
            "title": "MERCI POUR VOTRE DEMANDE",
            "summary": "Nous vous répondrons dès que possible. Vous recevrez sous peu un confirmation de réception ainsi qu'un résumé de votre commande par e-mail. <br><br>Si vous avez des questions, n'hésitez pas à nous envoyer un e-mail :<br>auftrag-mymotion@strauss.de",
            "toShop": "Retour à la boutique en ligne",
            "nextSteps": "PROCHAINES ÉTAPES"
        },
        "exitscreen": {
            "title": "SORTIR DU CONFIGURATEUR",
            "text": "Voulez-vous vraiment quitter l'éditeur ? Vous pouvez enregistrer votre configuration actuelle avec votre code créateur ci-dessous :",
            "cancel": "ANNULER",
            "quit": "ARRÊTER"
        },
        "linkfooter": {
            "home": "STRAUSS",
            "impressum": "Mentions légales",
            "eula": "CGV",
            "gdpr": "Protection des données",
            "osplatform": "OS-Platform",
            "cookie": "Configuration des cookies"
        },
        "logo": {
            "title": "CI CONFIGURATOR"
        },
        "misc": {
            "back": "Retour",
            "continue": "Continuer",
            "capture": "Télécharger l'image"
        },
        "country": {
            "title": "Remarquer",
            "text": "Vous avez spécifié un pays différent comme adresse de livraison. Nous mettons à jour la devise et la TVA en conséquence.",
            "close": "Continuer"
        },
        "errors": {
            "network": "Échec de connexion au serveur!",
            "notfound": "Ce code n'a pas été trouvé!",
            "unauthorized": "Vous n'êtes pas autorisé à accéder à cette ressource."
        }
    }
};

export default translation;